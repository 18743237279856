import { GifReader } from "omggif";

export const loadGifFrameList = async (
  gifUrl: string
): Promise<ImageData[]> => {
  if (!gifUrl.includes(".gif")) {
    return undefined;
  }
  const response = await fetch(gifUrl);
  const blob = await response.blob();
  const arrayBuffer = await blob.arrayBuffer();
  const intArray = new Uint8Array(arrayBuffer);

  const reader = new GifReader(intArray as Buffer);

  const info = reader.frameInfo(0);

  return new Array(reader.numFrames()).fill(0).map((_, k) => {
    const image = new ImageData(info.width, info.height);

    reader.decodeAndBlitFrameRGBA(k, image.data as any);

    return image;
  });
};

export const createSpriteSheet = (images: ImageData[]) => {
  const canvas = document.createElement("canvas");
  canvas.width = images[0].width * images.length;
  canvas.height = images[0].height;

  console.log(images);

  let sliceX = images.length;
  let sliceY = 1;

  images.forEach((image, k) => {
    let height = 0;
    if (sliceY == 1 && k * image.width > 2048) {
      height = images[0].height;
      sliceX = k - 1;
      sliceY += 1;
      canvas.width = images[0].width * (k - 1);
      canvas.height += images[0].height;
    } else if (k * image.width - (sliceY - 1) * canvas.width > canvas.width) {
      sliceY += 1;
      canvas.height += images[0].height;
    }
  });

  const ctx = canvas.getContext("2d");

  let row = 0;

  const height = images[0].height;

  images.forEach((image, k) => {
    k -= (sliceX + 1) * row;

    if (k * image.width > canvas.width) {
      k = 0;
      row += 1;
    }

    console.log(k * image.width, height * row);

    ctx.putImageData(image, k * image.width, height * row);
  });
  // Return Canvas as Data url
  return {
    url: canvas.toDataURL(),
    sliceX: sliceX,
    sliceY: sliceY,
    length: Math.min(sliceX * sliceY, images.length - 1),
  };
};

export function transparent() {
  const canvas = document.createElement("canvas");
  canvas.style.position = "absolute";
  canvas.width = 50;
  canvas.height = 50;

  return canvas.toDataURL();
}

export function shuffle(array: any[]) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex > 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

export async function preloadImages(sources: string[], path) {
  return new Promise<any>((resolve) => {
    const images: any = {};
    let loadedCounter = 0;
    sources.forEach((src) => {
      const image = new Image();
      // image.hidden = true;
      image.id = src;

      image.onload = () => {
        console.log(image);
        images[src] = image;
        loadedCounter += 1;
        document.body.appendChild(image);
        image.style.height = "0px";
        if (loadedCounter === sources.length) {
          resolve(() => images);
        }
      };
      image.onerror = () => {
        console.error(src + " was not found");
        loadedCounter += 1;
        if (loadedCounter === sources.length) {
          resolve(() => images);
        }
      };
      image.src = path + "/" + src;
    });
  });
}

export function getValue(v: string, fallback?: string) {
  if (!v) {
    return getValue(fallback);
  }
  v = v.toString();
  if (v == "-") {
    return getValue(fallback);
  }
  const split = v.split("-").filter((s) => s !== "");

  if (v == "100-") {
    console.log(split);
  }

  if (split.length === 1) {
    return +split[0];
  }

  const min = +split[0];
  const max = +split[1];

  const theValue = Math.random() * (max - min) + min;

  return theValue;
}
