import { createSpriteSheet, loadGifFrameList, preloadImages } from "./Utility";
import { Game } from "./game";

let config: any = {};

const customConfig: any = getSettingsFromURI(location.search);

// console.log(customConfig);

if (customConfig)
  for (const [key, value] of Object.entries(customConfig)) {
    config[key] = value;
  }

if (config.playerSprite) {
  loadGifFrameList(config.path + "/" + config.playerSprite).then(
    async (playerImages) => {
      if (playerImages) {
        const spriteSheet = createSpriteSheet(playerImages);

        console.log(spriteSheet, "spriteSheet");

        config.playerSheet = spriteSheet.url;
        config.sliceX = spriteSheet.sliceX;
        config.sliceY = spriteSheet.sliceY;
        config.length = spriteSheet.length;
      }

      loadGifFrameList(config.path + "/" + config.jumpSprite).then(
        async (jumpImages) => {
          if (jumpImages) {
            const jumpSheet = createSpriteSheet(jumpImages);

            console.log(jumpSheet, "jumpSheet");

            config.jumpSheet = jumpSheet.url;
            config.jump = {
              sliceX: jumpSheet.sliceX,
              sliceY: jumpSheet.sliceY,
              length: jumpSheet.length,
            };
          }

          loadGifFrameList(config.path + "/" + config.deadSprite).then(
            async (deadFrames) => {
              if (deadFrames) {
                const deadSheet = createSpriteSheet(deadFrames);
                console.log(deadSheet, "deadSheet");

                config.deadSheet = deadSheet.url;
                config.dead = {
                  sliceX: deadSheet.sliceX,
                  sliceY: deadSheet.sliceY,
                  length: deadSheet.length,
                };
              }
              const parallaxLayers = config.parallaxLayers.map((layer: any) => {
                return layer.image;
              });

              if (!config.collectables) {
                config.collectables = [];
              }

              const obstacles = config.obstacles.map((obstacle: any) => {
                return obstacle.image;
              });

              const collectables = config.collectables.map(
                (collectable: any) => {
                  return collectable.image;
                }
              );

              const collectablesArray = [];
              config.collectables.forEach((c) => {
                for (let i = 0; i < c.chance; i++) {
                  collectablesArray.push(c);
                }
              });

              if (config.playerX === undefined) {
                config.playerX = 20;
              }

              config.collectables = collectablesArray;

              preloadImages(
                [
                  ...parallaxLayers,
                  ...collectables,
                  ...obstacles,
                  config.playerSprite,
                  config.jumpSprite,
                  config.deadSprite,
                ],
                config.path
              ).then((imgs) => {
                setTimeout(() => {
                  new Game(config, imgs);
                }, 250);
              });
            }
          );
        }
      );
    }
  );
}

function getSettingsFromURI(uri: string) {
  console.log(uri);
  uri = uri.replace("?", "");
  let settingString;
  const split = uri.split("&");
  for (let s of split) {
    console.log(s);
    if (s[0] === "s") {
      settingString = atob(s.split("=")[1]);
    }
  }

  const lines = settingString.split("\n");
  for (const line of lines) {
    const splitBy = line.split("=");
    if (splitBy[0] == "config") {
      const c = JSON.parse(splitBy[1]);
      return c;
    }
  }
  return undefined;

  if (settingString === undefined) {
    return null;
  }

  return JSON.parse(settingString.replace("config=", ""));
}

function isString(value: string) {
  if (!isNaN(Number(value)) || value === "true" || value === "false") {
    return false;
  }
  return true;
}
