// @ts-nocheck

import kaboom from "kaboom";
import { Platform } from "./Platform";
import { spawn } from "child_process";
import { getValue, transparent } from "./Utility";
import { get } from "http";

export class Game {
  config: any;

  floorHeight: number;

  images: any;

  parallaxLayers: any[] = [];

  firstTime = true;

  platform: Platform;

  objects = [];

  constructor(config: any, images: any) {
    Game.vliegers = [];

    this.config = config;

    Game.platform = new Platform();

    this.images = images;

    kaboom({
      background: [0, 0, 0, 0],
      debug: true,
      pixelDensity: 1,
      loadingScreen: false,
    });

    Game.floorHeight = config.floorHeight;

    Game.sprites = [];
    config.parallaxLayers
      .filter((l) => l.constant)
      .forEach((layer: any) => {
        const src = document.getElementById(layer.image)?.src;

        const div = document.createElement("div");

        div.style.backgroundImage = `url(${src})`;
        div.style.backgroundSize = "cover";
        div.style.height = layer.height + "px";
        div.style.pointerEvents = "none";
        div.style.zIndex = layer.zIndex;
        div.style.position = "absolute";
        div.style.overflow = "hidden";
        div.style.width = "100%";
        div.style.backgroundPositionX = "0px";
        // div.style.backgroundRepeat = "repeat-x";
        div.style.bottom = config.floorHeight + layer.offset + "px";
        // div.style.transition = "background-position 500ms linear";

        this.parallaxLayers.push({ element: div, config: layer });
        document.body.appendChild(div);
      });

    // config.vliegers.forEach((vlieger: any) => {

    // });

    this.lastfame = Date.now();
    this.runParallaxUpdate();

    scene("niks", () => {
      this.speed = config.speed;

      add([
        rect(width(), Game.floorHeight),
        // outline(4),
        pos(0, height()),
        anchor("botleft"),
        area(),
        body({ isStatic: true }),
        color(config.floorColor),
        "floor",
      ]);

      if (Game.timeouts) Game.timeouts.forEach((t) => clearTimeout(t));

      config.obstacles.forEach((obstacle: any) => {
        loadSprite(obstacle.image, config.path + obstacle.image);
        const s = sprite(obstacle.image, {
          height: obstacle.height ? obstacle.height : config.obstacleHeight,
        });
        for (let i = 0; i < obstacle.chance; i++) {
          Game.sprites.push({
            sprite: s,
            heightRange: obstacle.heightRange,
            height: obstacle.height,
            url: obstacle.image,
            // collisionOffsetX: obstacle.collisionOffsetX,
            // collisionOffsetY: obstacle.collisionOffsetY,
          });
          add([s, pos(-100000, -10000)]);
        }
      });

      add([sprite("player"), pos(-100000, -10000)]);

      onLoad(() => {
        document.getElementById("loading-screen")?.remove();
        Game.platform.ready();
        console.log(this.config.editor, "EDITOR");
        if (this.config.editor == true && this.firstTime) {
          setTimeout(() => {
            this.firstTime = false;
            go("game");
            this.init(config, Game.platform);
          }, 100);
        }
      });
      // onUpdate((delta: number) => {
      //   this.parallaxLayers.forEach((layer) => {
      //     layer.element.style.backgroundPositionX =
      //       parseInt(layer.element.style.backgroundPositionX) -
      //       (config.speed * layer.config.speed) / 75 +
      //       "px";
      //   });
      //   // scoreLabel.text = score;
      // });
    });

    go("niks");

    // if (config.editor == true) {
    //   if (this.firstTime)
    //     setTimeout(() => {
    //       this.firstTime = false;
    //       go("game");
    //       this.init(config, Game.platform);

    //       Game.platform.ready();
    //     }, 100);
    // }

    Game.platform.init({
      play: () => {
        go("game");
        this.init(config, Game.platform);
      },
      restart: () => {
        // this.init(config, platform);
        document.querySelectorAll(".vlieger").forEach((a) => a.remove());
        Game.vliegers = [];
        Game.platform.ready();

        go("niks");
      },
    });

    this.init.bind(this);

    this.init(config, Game.platform);
  }

  lastfame;

  runParallaxUpdate() {
    let delta = (Date.now() - this.lastfame) / 1000;
    this.lastfame = Date.now();

    this.parallaxLayers.forEach((layer) => {
      layer.element.style.backgroundPositionX =
        parseFloat(layer.element.style.backgroundPositionX) -
        this.speed * delta * layer.config.speed +
        "px";
    });
    Game.vliegers.forEach((vlieger) => {
      if (vlieger.element.style.animation) {
        return;
      }
      const pos = vlieger.element.style.transform.replace("100vw", "");
      const numer = pos.replace("translateX(", "").replace("px) scale(1)", "");

      vlieger.element.style.transform = `translateX(${
        +numer - this.speed * delta * (vlieger.config?.speed || 1)
      }px) scale(1)`;

      // vlieger.element.style.transform = `translateX(${0}px)`;
      if (+numer < -vlieger.element.clientWidth) {
        vlieger.element.remove();
      }
    });
    requestAnimationFrame(() => {
      // if (!Game.started) return;

      this.runParallaxUpdate();
    });
  }

  spawnVlieger(key?: string) {
    let vlieger;
    if (!key) {
      const filtered = this.config.parallaxLayers.filter(
        (v) => !v.constant && (v.frequency == "-" || !v.frequency)
      );
      vlieger = filtered[Math.floor(Math.random() * filtered.length)];
    } else {
      vlieger = this.config.parallaxLayers.find((v) => (v.image = key));
    }
    if (key && (vlieger.frequency == "-" || !vlieger.frequency)) return;
    if (!vlieger) return;
    const src = document.getElementById(vlieger.image)?.src;

    const div = new Image();

    div.className = "vlieger";

    // div.onload = () => {
    div.height = vlieger.height;

    div.style.pointerEvents = "none";
    div.style.zIndex = vlieger.zIndex;
    div.style.height = vlieger.height + "px";
    div.style.position = "absolute";
    div.style.overflow = "hidden";

    div.style.display = "block";
    div.style.left = 0;

    div.style.transform = `translateX(${window.innerWidth}px)`;

    div.style.bottom = this.config.floorHeight + vlieger.offset + "px";

    Game.vliegers.push({ element: div, config: vlieger });
    document.body.appendChild(div);

    setTimeout(() => {
      if (!Game.started) return;
      this.spawnVlieger(key);
    }, getValue(vlieger.frequency, this.config.frequency));
    // };

    div.src = src;
  }

  speed: number;

  play() {}

  init(config: any, platform: Platform) {
    // setBackground();

    // load assets
    if (config.jumpSheet) {
      loadSprite("jump", config.jumpSheet, {
        sliceX: +config.jump.sliceX,
        sliceY: +config.jump.sliceY,
        anims: {
          run: {
            from: 0,
            to: +config.jump.length - 2,
            speed: +config.jumpSpeed,
            loop: true,
          },
        },
      });
    } else {
      loadSprite("jump", config.path + "/" + config.jumpSprite);
    }
    if (config.deadSheet) {
      loadSprite("dead", config.deadSheet, {
        sliceX: +config.dead.sliceX,
        sliceY: +config.dead.sliceY,
        anims: {
          run: {
            from: 0,
            to: +config.dead.length - 2,
            speed: +config.deadSpeed,
            loop: true,
          },
        },
      });
    } else {
      loadSprite("dead", config.path + "/" + config.deadSprite);
    }
    if (config.playerSheet) {
      loadSprite("player", config.playerSheet, {
        sliceX: +config.sliceX,
        sliceY: +config.sliceY,
        anims: {
          run: {
            from: 0,
            to: +config.length - 2,
            speed: +config.runSpeed,
            loop: true,
          },
        },
      });
    } else {
      loadSprite("player", config.path + "/" + config.playerSprite);
    }

    loadSprite("transparent", transparent());

    scene("game", () => {
      Game.objects = [];
      Game.timeouts = [];

      Game.lastPipeHeight = 0;
      // define gravity
      setGravity(config.gravity);

      Game.timeouts.push(
        setTimeout(() => {
          this.spawnVlieger();
          // this.config.parallaxLayers.forEach((vlieger: any) => {
          //   if (!vlieger.constant) this.spawnVlieger(vlieger.image);
          // });
        }, config.startAfter)
      );

      const speedUp = () => {
        if (speed >= (config.maxSpeed ? config.maxSpeed : 5000)) return;
        let timeout = setTimeout(() => {
          if (!Game.started) return;

          speed += config.speedUpAmount;

          if (speed > config.maxSpeed) speed = config.maxSpeed;
          this.speed = speed;

          speedUp();
        }, config.speedUpInterval);
        Game.timeouts.push(timeout);
      };

      const theSprite = sprite("player", { width: config.playerWidth });

      const offsetX =
        (config.playerWidth * (1 - config.collisionScale / 100)) / 2;

      const playerImg = document.getElementById(
        config.playerSprite
      ) as HTMLImageElement;
      console.log(playerImg.naturalHeight);

      const collisionWidth = (config.playerWidth * config.collisionScale) / 100;
      const aspectRatio = playerImg.naturalWidth / playerImg.naturalHeight;
      const collisionHeight = collisionWidth / aspectRatio;
      const offsetY =
        (playerImg.naturalHeight *
          (config.playerWidth / playerImg.naturalWidth) *
          (1 - config.collisionScale / 100)) /
        2;
      // add a game object to screen
      const player = add([
        // list of components
        sprite("jump", { width: config.playerWidth }),
        anchor("botleft"),
        outline(1),

        pos(config.playerX, 0),
        area(),
        // area({
        //   scale: config.collisionScale / 100,
        //   offset: new Vec2(offsetX, offsetY),
        // }),
        body(),
        "player",
      ]);

      console.log(offsetX, offsetY);

      let debug;

      if (config.debug) {
        debug = add([
          pos(config.playerX + offsetX, 0),
          rect(collisionWidth, collisionHeight),
          color([0, 0, 0, 0]),
          outline(2),
          anchor("botleft"),
          area(),
        ]);
      } else {
        debug = add([
          pos(config.playerX + offsetX, 0),
          sprite("transparent", {
            width: collisionWidth,
            height: collisionHeight,
          }),
          // config.debug ? color() : undefined,
          anchor("botleft"),
          area(),
        ]);
      }

      // floor
      add([
        rect(width(), Game.floorHeight),
        // outline(4),
        pos(0, height()),
        anchor("botleft"),
        area(),
        body({ isStatic: true }),
        color(config.floorColor),
        z(5),
        "floor",
      ]);

      let jumped = false;

      function jump() {
        if (!Game.started) return;
        if (player.isGrounded() && !floating) {
          player.use(sprite("jump", { width: config.playerWidth }));
          if (config.jumpSheet) player.play("run");
          const maxHeight =
            (config.jumpHeight * config.jumpHeight) / (2 * config.gravity);
          if (config.instantJump) {
            player.pos.y =
              window.innerHeight -
              config.floorHeight -
              maxHeight +
              player.height;

            floating = true;
            setGravity(0);
            clearTimeout(slideDownTimeout);
            slideDownTimeout = setTimeout(
              () => {
                if (floating) setGravity(config.gravity);
              },
              config.floatTime ? config.floatTime : 500
            );
            Game.objects
              .filter((ob) => ob.div && ob.pos.x > -ob.width)
              .forEach((obj, i) => {
                if (
                  obj.pos.x < player.pos.x + player.width &&
                  obj.pos.x + obj.width > player.pos.x
                ) {
                  const div = obj.div;
                  if (div) {
                    obj.destroy();

                    score += obj.points;
                    Game.platform.sendScore(score, true);

                    animate(div);
                  }
                }
              });
          } else {
            const actualJumpForce = config.jumpHeight * 3;
            const actualGravity =
              (actualJumpForce * actualJumpForce) / (maxHeight * 2);
            setGravity(actualGravity);
            player.jump(actualJumpForce);
            jumped = true;
          }
        } else {
          // if (Game.started) {
          console.log("doe dit");
          // setTimeout(() => {
          // player.vel.y = null;
          player.pos.y = 10000;
          // floating = false;

          // if(Game.objects.)

          Game.objects
            .filter((ob) => ob.div && ob.pos.x > -ob.width)
            .forEach((obj, i) => {
              if (
                obj.pos.x < player.pos.x + player.width &&
                obj.pos.x + obj.width > player.pos.x
              ) {
                const div = obj.div;
                if (div) {
                  obj.destroy();

                  score += obj.points;
                  Game.platform.sendScore(score, true);

                  animate(div);

                  // div.style.zIndex = -1;
                }
              }
            });

          // }, 50);
          // }
        }
      }

      let speed = config.speed;

      Game.started = false;

      let dead = false;

      // jump when user press space
      onKeyPress("space", jump);
      onClick(jump);

      function spawnTree() {
        // add tree obj

        // const sprite = sprite("pipe.png");
        // console.log(sprite.height);

        const randomIndex = Math.floor(Math.random() * Game.sprites.length);
        const obstacle = Game.sprites[randomIndex];
        const theHeight =
          (obstacle.height ? obstacle.height : config.obstacleHeight) *
          (1 -
            getValue(
              obstacle.heightRange && obstacle.heightRange !== "-"
                ? obstacle.heightRange
                : config.obstacleHeightRange
            ) /
              100);

        console.log("SPRITE", obstacle.sprite);

        if (!Game.justSpawned) {
          // Game.objects.push(
          //   add([
          //     obstacle.sprite,
          //     area(),
          //     // outline(4),
          //     pos(width(), height() - (Game.floorHeight - theHeight)),
          //     anchor("botleft"),
          //     offscreen({ destroy: true }),
          //     "tree",
          //     z(0),
          //   ])
          // );

          // if (obstacle.url.includes(".gif")) {
          const div = new Image();
          div.src = config.path + "/" + obstacle.url;

          if (config.debug) {
            div.style.border = "1px solid black";
          }
          div.style.height = obstacle.sprite.height + "px";
          div.style.pointerEvents = "none";
          div.style.bottom = Game.floorHeight - theHeight + "px";
          div.style.left = 0;
          div.style.position = "absolute";
          div.style.zIndex = -1;
          div.style.transform = `translateX(${window.innerWidth}px) scale(1)`;
          div.className = "vlieger";

          document.body.appendChild(div);
          Game.vliegers.push({ element: div, config: obstacle });
          Game.objects.push(
            add([
              obstacle.sprite,
              area(),
              // outline(4),
              color([0, 0, 0, 0]),
              pos(width(), height() - (Game.floorHeight - theHeight)),
              anchor("botleft"),
              // offscreen({ destroy: true }),
              "tree",
              z(0),
            ])
          );

          const object = Game.objects[Game.objects.length - 1];

          object.onDestroy = () => {
            Game.objects = Game.objects.filter((o) => o !== object);
          };
          Game.justSpawned = true;
          Game.lastPipeHeight = theHeight;
        }

        // wait a random amount of time to spawn next tree
        const waitAmount =
          (getValue(config.timeBetweenObstacles) / 1000) *
          (config.speed / speed);
        wait(waitAmount, spawnTree);
      }

      // start spawning trees
      // spawnTree();

      debug.onCollide("tree", (tree) => {
        if (Game.timeouts) Game.timeouts.forEach((t) => clearTimeout(t));
        this.speed = 0;
        if (config.deadSprite) {
          player.use(sprite("dead", { width: config.playerWidth }));
          if (config.deadSheet) player.play("run");
        } else {
          player.use(sprite("jump", { width: config.playerWidth }));
          if (config.jumpSheet) player.play("run");
        }
        player.vel.y = 0;
        platform.gameover(score);
        dead = true;
        Game.started = false;
      });

      debug.onCollideUpdate("tree", () => {
        Game.started = false;
        this.speed = 0;
        dead = true;
      });

      let lastFrame = Date.now();

      player.onCollide("floor", () => {
        if (dead) return;
        player.vel.y = 0;
        jumped = false;
        floating = false;
        setGravity(config.gravity);
        if (!Game.started) {
          console.log("first");
          Game.justSpawned = false;

          wait(rand(0.5, 1), spawnTree);
          // spawnTree();
          Game.started = true;
          wait(rand(1.5, 2), () => spawnCollectable(this));

          platform.gamestarted();
          speedUp();
        }
        if (Game.justJumped) {
          Game.justJumped = false;
          jump();
          return;
        }
        if (!dead) {
          console.log(player.sprite);
          player.use(sprite("player", { width: config.playerWidth }));
          if (config.playerSheet) player.play("run");
        }
      });

      function animate(div) {
        if (
          config.collectableAnimation &&
          config.collectableAnimation !== "none"
        ) {
          div.style.setProperty("--player-pos", config.playerX + "px");
          div.style.setProperty("--pos", div.style.transform);
          div.style.setProperty(
            "--opacity",
            config.collectableAnimationOpacity ? 0 : 1
          );
          div.style.setProperty(
            "--scale",
            config.collectableAnimationScale / 100
          );
          div.style.animation = `${config.collectableAnimation} ${config.collectableAnimationTime}ms ${config.collectableAnimationEase} 0ms`;
        } else {
          div.remove();
        }
      }

      function spawnCollectable(self = this) {
        if (!self.config.collectableChance || !Game.started) return;
        const spawn = Math.random() * self.config.collectableChance <= 1;

        if (spawn && !Game.justSpawned) {
          const collectable =
            self.config.collectables[
              Math.floor(Math.random() * self.config.collectables.length)
            ];

          if (!collectable) return;

          const collectableHeight =
            collectable.height ?? self.config.collectableHeight;

          const maxHeight =
            (self.config.jumpHeight * self.config.jumpHeight) /
              (2 * self.config.gravity) -
            collectableHeight;

          const percent = getValue(collectable.yRange) / 100;
          const y = percent * maxHeight;

          const div = self.createCollectableElement(
            collectable,
            self,
            y,
            percent
          );

          const object = add([
            // rect(div.width, div.height),

            sprite("transparent", { width: div.width, height: div.height }),
            area({}),
            pos(width(), height() - Game.floorHeight - y),
            anchor("botleft"),
            color(),
            // move(LEFT, speed),
            offscreen({ destroy: true }),
            "collectable",
          ]);

          object.div = div;
          object.points = collectable.points;

          Game.justSpawned = true;

          // object.onCollide("tree", (tree) => {
          //   object.pos.x = tree.pos.x + tree.width + div.width;
          //   div.style.transform = `translateX(${
          //     tree.pos.x + tree.width + div.width + "px"
          //   }) scale(1)`;
          // });

          // object.onDestroy = () => {
          //   Game.objects = Game.objects.filter((o) => o !== object);
          // };

          object.onCollideUpdate("player", () => {
            object.destroy();
            Game.vliegers = Game.vliegers.filter((v) => v.element !== div);

            score += collectable.points;
            Game.platform.sendScore(score, true);

            animate(div);
          });
          Game.vliegers.push({ element: div, config: collectable });

          Game.objects.push(object);
        }

        // wait a random amount of time to spawn next collectable
        wait(
          (self.config.collectableTimeBetween / 1000) *
            (self.config.speed / speed),
          () => {
            spawnCollectable(self);
          }
        );
      }

      // keep track of score
      let score = 0;

      let floating = false;

      let slideDownTimeout: any;

      let lastY = 0;

      onUpdate(() => {
        if (!Game.started && !dead) {
          if (
            player &&
            Math.round(player.pos.y) >
              window.innerHeight - config.floorHeight + 5
          ) {
            Game.justSpawned = false;
            wait(rand(0.5, 1), spawnTree);
            // spawnTree();
            Game.started = true;
            wait(rand(1.5, 2), () => spawnCollectable(this));

            platform.gamestarted();
            speedUp();
          }
        }
        if (!Game.started) return;

        score++;
        platform.sendScore(score, false);

        const delta = (Date.now() - lastFrame) / 1000;

        lastFrame = Date.now();

        const jumpHeight =
          (config.jumpHeight * config.jumpHeight) / (2 * config.gravity);

        if (
          !config.instantJump &&
          !floating &&
          player &&
          (Math.floor(player.pos.y - player.height) <=
            Math.ceil(window.innerHeight - config.floorHeight - jumpHeight) ||
            (jumped && player.pos.y > lastY))
        ) {
          player.pos.y =
            window.innerHeight -
            config.floorHeight -
            jumpHeight +
            player.height;
          floating = true;
          setGravity(0);
          player.vel.y = 0;

          clearTimeout(slideDownTimeout);
          slideDownTimeout = setTimeout(
            () => {
              if (floating) setGravity(config.gravity);
            },
            config.floatTime !== undefined ? config.floatTime : 500
          );
        }
        if (!floating) {
          lastY = player.pos.y;
        }

        Game.objects.forEach((object, i) => {
          object.pos.x = object.pos.x - speed * delta;

          if (i == Game.objects.length - 1) {
            if (object.pos.x < window.innerWidth - object.width) {
              Game.justSpawned = false;
            } else {
              Game.justSpawned = true;
            }
          }
        });

        if (
          player &&
          Math.round(player.pos.y) > window.innerHeight - config.floorHeight + 5
        ) {
          player.velocity = 0;
          player.pos.y = window.innerHeight - config.floorHeight + 5;
          setGravity(config.gravity);
          floating = false;
          clearTimeout(slideDownTimeout);
        }
        debug.pos.y = player.pos.y - offsetY;
      });
    });
  }

  createCollectableElement(collectable, self, y, percent) {
    const div = new Image();

    div.className = "vlieger";

    const src = document.getElementById(collectable.image)?.src;

    div.src = src;

    const height = collectable.height ?? self.config.collectableHeight;

    div.height = height + "px";

    div.style.pointerEvents = "none";
    div.style.zIndex = 10000;
    div.style.height = height + "px";
    div.style.position = "absolute";
    div.style.overflow = "hidden";

    div.onanimationend = () => {
      div.remove();
      Game.vliegers = Game.vliegers.filter((v) => v.element !== div);
    };
    div.style.bottom =
      self.config.floorHeight + y - div.height * percent + "px";

    div.style.setProperty(
      "--bottom",
      Math.round(self.config.floorHeight + y - div.height * percent) + "px"
    );

    div.style.opacity = 1;

    div.style.display = "block";
    div.style.left = 0;

    if (self.config.debug) {
      div.style.border = "1px solid black";
    }

    div.style.transform = `translateX(${window.innerWidth}px) scale(1)`;
    div.style.transformOrigin = "center";

    document.body.appendChild(div);

    return div;
  }
}
